import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Breadcrumbs from '../../../components/Breadcrumbs';
import YoutubeIcon from '../../../components/icons/YoutubeIcon';

import Link from '../../../components/Link';
import Seo from '../../../components/Seo';

const PisniLiteraturnohoPokhodzhenniaPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "pisni-literaturnoho-pokhodzhennia"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          author
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Українські пісні літературного походження"
        description="Українські пісні літературного походження різних авторів. Пісні Івана Котляревського, Тараса Шевченка, Григорія Сковороди та інших."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Українські пісні літературного походження
          </h1>
          <p className="typo-body mt-4 max-w-xl">
            Пісні літературного походження — це твори, що увійшли в народну словесність з професійної літератури.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список пісень літературного походження
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-6 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {`${song.title} (${song.author})`}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру пісні літературного походження
        </h2>
        <p className="typo-body mt-4">
          Пісні літературного походження — це так звані фольклоризовані твори, тобто ті, які настільки близькі за
          світоглядними позиціями і за поетикою до народної творчості, що пережили не одне покоління на всіх просторах
          України і навіть поза її межами. Так сталося із славнозвісною піснею С. Руданського «Повій, вітре, на Вкраїну»,
          з глибоко ліричною піснею Л. Глібова «Стоїть гора високая». Чимало пісень на тексти Т. Шевченка, І. Франка,
          драматургів М. Старицького, М. Кропивницького стали народними піснями. Майже всі пісні з п’єси І. Котляревського
          «Наталка Полтавка» співають в народі, і далеко не всі знають, хто їхній автор. А й справді, хто? Хіба названі
          і неназвані поети не перебували під впливом традиційної народної пісенності, коли компонували свої твори?
          Іноді не можна навіть з упевненістю відділити «фольклоризовані пісні» від власне фольклорних. Адже балада
          «Ой не ходи, Грицю» відома всім як народна пісня; вона звучить і в одноіменній драмі М. Старицького, але
          знаходиться все більше підтверджень того, що авторкою балади була Маруся Чурай.
        </p>
        <p className="typo-body mt-4">
          Пісні літературного походження, крім усного переймання, поширюються через рукописні збірники, що дійшли до нас
          з XVIII сторіччя, продовжують укладатися любителями ще й тепер, а також через численні публікації; одна з
          найбільших — збірник «Пісні літературного походження», який вийшов у 1978 р. у серії «Українська народна
          творчість».

        </p>
      </div>
    </>
  );
};

export default PisniLiteraturnohoPokhodzhenniaPage;
